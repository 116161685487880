function createRangeTypeList() {
    return {
        GT: {
            label: ">",
            value: "GT",
            describe: "大于"
        },
        LT: {
            label: "<",
            value: "LT",
            describe: "小于"
        },
        EQ: {
            label: "=",
            value: "EQ",
            describe: "等于"
        },
        // 不要此项（沟通结果 2022-6-8
        // NOT_BETWEEN: {
        //     label: "()",
        //     value: "NOT_BETWEEN",
        //     describe: "不在范围内"
        // },
        BETWEEN: {
            label: "[]",
            value: "BETWEEN",
            describe: "范围(包含)"
        }
    }
}

function createMoneyTypeList() {
    return {
        CNY: {
            label: "元",
            value: "CNY",
            describe: "人民币"
        },
        TWD: {
            label: "台币",
            value: "TWD",
            describe: "新台币"
        },
        JPY: {
            label: "円",
            value: "JPY",
            describe: "日元"
        },
        USD: {
            label: "美元",
            value: "USD",
            describe: "美元"
        },
    }
}

function createPriceTypeList() {
    return {
        /* FLOAT: {
            label: "浮动",
            value: "FLOAT",
            describe: "浮动"
        }, */
        FIXED: {
            label: "固定值",
            value: "FIXED",
            describe: "固定值"
        },
        LEVEL:{
            label:"等级基数",
            value:'LEVEL',
            describe: "等级基数"
        }
    }
}

export {
    createRangeTypeList,
    createMoneyTypeList,
    createPriceTypeList,
}