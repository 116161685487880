function createUnitTypeList() {
    return {
        EACH: {
            label: "个",
            value: "EACH",
            describe: "个(阶段/月份/默认)"
        },
        CHAPTER: {
            label: "话",
            value: "CHAPTER",
            describe: "话(作品每一章)"
        },
        VOLUME: {
            label: "部",
            value: "VOLUME",
            describe: "部(整部作品)"
        },
        PAGE: {
            label: "页",
            value: "PAGE",
            describe: "页"
        },
        THOUSAND: {
            label: "千字",
            value: "THOUSAND",
            describe: "千字"
        },
        WORD: {
            label: "字",
            value: "WORD",
            describe: "字"
        },
        GRID: {
            label: "格",
            value: "GRID",
            describe: "格"
        },
        PERCENT: {
            label: "%",
            value: "PERCENT",
            describe: "%"
        }
    }
}

function createPriceUnitTypeList() {
    return createUnitTypeList();
}

export {
    createUnitTypeList,
    createPriceUnitTypeList,
}